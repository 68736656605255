import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContractLayout from "../../components/ContractLayout"
import SEO from "../../components/SEO"
import ContractResult from "../../components/ContractResult"
import Step from "../../components/Step"
import ContentLayout from "../../components/ContentLayout"
import style from "./index.module.scss"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <ContractLayout>
      <SEO
        title={intl.formatMessage({
          id: "seo.home.title",
        })}
        lang={intl.formatMessage({
          id: "seo.home.lang",
        })}
        description={intl.formatMessage({
          id: "seo.home.description",
        })}
        keywords={intl.formatMessage({
          id: "seo.home.keywords",
        })}
      />
      <div className={style.progressContainer}>
        <ContentLayout>
          <div>
            <div className={style.progressInnerContainer}>
              <div className={style.progressTitle}>合同进度</div>
              <Step />
            </div>
          </div>
        </ContentLayout>
      </div>
      <ContentLayout>
        <ContractResult pageType="view" />
      </ContentLayout>
    </ContractLayout>
  )
}

export default IndexPage
